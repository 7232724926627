/*import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'http:localhost:3001/graphql', // Replace with your GraphQL endpoint
});

const authLink = setContext((_, { headers }) => {
  // If you need to set any headers, such as for authentication, you can do it here
  return {
    headers: {
      ...headers,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
*/
// src/apolloClient.js
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT, // Use the environment variable
  }),
  cache: new InMemoryCache(),
});

export default client;

