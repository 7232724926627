// Function to convert image to base64
export const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const MAX_SIZE_MB = 20; // 40 MB limit
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert to bytes

    // Check if file size exceeds 40MB
    if (file.size > MAX_SIZE_BYTES) {
      reject(new Error(`File is too large. Maximum allowed size is ${MAX_SIZE_MB}MB`));
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file); // Read the file as a base64 encoded string
    reader.onload = () => {
      const base64Image = reader.result;

      // Calculate base64 size in bytes (excluding "data:image/*;base64," prefix)
      const base64SizeBytes = (base64Image.length * 3) / 4 - 1; // Remove the overhead of the prefix
      const base64SizeMB = base64SizeBytes / (1024 * 1024); // Convert to MB

      console.log(`Original file size: ${(file.size / (1024 * 1024)).toFixed(2)} MB`);
      console.log(`Base64 file size: ${base64SizeMB.toFixed(2)} MB`);

      resolve(base64Image); // Resolve with the base64 string
    };

    reader.onerror = (error) => reject(error);
  });
};
