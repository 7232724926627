import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import MerchandiseItem from '../components/MerchandiseItem';
import { convertImageToBase64 } from '../utils/imageUtils';  // Import the utility function


const GET_MERCH_PAGE = gql`
  query GetMerchPage($input: MerchInput!) {
  getMerchPage(input: $input) {
    id
    title
    description
    ownerId
    items {
      id
      title
      description
      price
      attributes
      mainImage
      merchandisePageId
    }
  }
}

`;

const CREATE_MERCH_ITEM = gql`
  mutation Mutation($input: MerchandiseItemInput!) {
  CreateMerchandiseItem(input: $input) {
    id
    title
    description
    merchandisePageId
    mainImage
  }
}
`;

const UPDATE_MERCH_ITEM = gql`
  mutation UpdateMerchItem($id: ID!, $input: MerchItemInput!) {
    updateMerchItem(id: $id, input: $input) {
      id
      title
      description
      price
      attributes
      mainImage
      additionalImages
      merchandisePageId
    }
  }
`;

const DELETE_MERCH_ITEM = gql`
  mutation DeleteMerchItem($id: ID!) {
    deleteMerchItem(id: $id) {
      id
    }
  }
`;

// Your existing GraphQL queries and mutations here...

const MerchandiseDashboard = () => {
  const { merchPageId } = useParams();
  const token = sessionStorage.getItem('token');
  const MerchInput = merchPageId;

  const { loading, error, data, refetch } = useQuery(GET_MERCH_PAGE, {
    variables: { input: { id: merchPageId } },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const [createMerchItem] = useMutation(CREATE_MERCH_ITEM);
  const [updateMerchItem] = useMutation(UPDATE_MERCH_ITEM);
  const [deleteMerchItem] = useMutation(DELETE_MERCH_ITEM);

  const [editingItem, setEditingItem] = useState(null);
  const [newItem, setNewItem] = useState({ title: '', description: '', price: 0, mainImageBase64: '' });

  const [imageFile, setImageFile] = useState(null);  // State to store the selected file

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { title, description, items } = data.getMerchPage;
  const backgroundimage = `https://gigabout.com.au/merchimages/${merchPageId}/background.jpg`;

  // Handle image file change
  const handleImageChange = async (e) => {
    const file = e.target.files[0];  // Get the selected file
    if (file) {
      try {
        const base64Image = await convertImageToBase64(file);  // Convert to base64
        setNewItem({ ...newItem, mainImageBase64: base64Image });    // Update newItem with base64 image
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    }
  };

  const handleCreateOrUpdate = async () => {
    if (editingItem) {
      await updateMerchItem({ variables: { id: editingItem.id, input: newItem } });
    } else {
      const payload = { 
        title: newItem.title,
        description: newItem.description,
        price: newItem.price,
        mainImageBase64: newItem.mainImageBase64,
        merchandisePageId: merchPageId
      };
      const payloadSize = new Blob([JSON.stringify(payload)]).size;
      console.log(`Payload size: ${payloadSize / (1024 * 1024)} MB`);
      await createMerchItem({ variables: { input: { ...newItem, merchandisePageId: merchPageId } } });
    }
    refetch();
    setNewItem({ title: '', description: '', price: 0, mainImage: '', additionalImages: [] });
    setEditingItem(null);
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setNewItem(item);
  };

  const handleDelete = async (id) => {
    await deleteMerchItem({ variables: { id } });
    refetch();
  };

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',
        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.886)',
          zIndex: 1,
        }}
      />
      <div style={{ position: 'relative', zIndex: 2 }}>
        <h1>{title}</h1>
        
        <p>{description}</p>

        {/* Merchandise Item Form */}
        <div className="merchandise-form">
          <input
            type="text"
            placeholder="Title"
            value={newItem.title}
            onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
          />
          <textarea
            placeholder="Description"
            value={newItem.description}
            onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
          />
          <input
            type="number"
            placeholder="Price"
            value={newItem.price}
            onChange={(e) => setNewItem({ ...newItem, price: parseFloat(e.target.value) })}
          />
          
          {/* Image Picker */}
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleImageChange}
          />
          
          <button onClick={handleCreateOrUpdate}>{editingItem ? 'Update Item' : 'Create Item'}</button>
          {editingItem && <button onClick={() => setEditingItem(null)}>Cancel Edit</button>}
        </div>
        

        {/* Merchandise Items List */}
        <h2>Manage Products</h2>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
              gap: '16px',
              backgroundColor: 'transparent',
            }}
          >
            {items.map((item) => (
              <div key={item.id}>
                <MerchandiseItem item={item} />
                <button onClick={() => handleEdit(item)}>Edit</button>
                <button onClick={() => handleDelete(item.id)}>Delete</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchandiseDashboard;

